$common-margin: 10vh;
$common-margin--px: 60px;
$common-left: 450px;

$base-color: #f4f4f4;
$shine-color: #fefefe;
$animation-duration: 1.6s;

@keyframes shine-avatar {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 750px;
  }
}
@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 100%;
}

@mixin slicknav {
  .slick-slide {
    outline: none;
  }
  .slick-arrow {
    z-index: 9;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
    overflow: visible;
    font-size: 1rem;
    font-weight: 600;
    color: $para !important;
    transform: scale(1);
    @include transition;
    top: auto;
    bottom: 0;
    svg {
      margin-right: 8px;
      transform: translateY(1px);
      path {
        fill: $para;
      }
      path:first-child {
        fill: $secondary-color;
      }
    }
    &:before {
      content: none;
    }
  }
  .slick-prev {
    left: calc(100% - 155px);
  }
  .slick-next {
    right: 0px;
  }
  .carousel {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    &.slick-initialized {
      visibility: visible;
      opacity: 1;
    }
  }
  .slick-disabled {
    opacity: 0.5;
    svg {
      opacity: 0;
    }
  }
}

@mixin slicknavnormal {
  .slick-slide {
    outline: none;
  }
  .slick-arrow {
    z-index: 9;
    width: 70px;
    height: 100%;
    background-color: transparent;
    border-radius: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
    overflow: visible;
    font-size: 1rem;
    font-weight: 600;
    transform: scale(1);
    @include transition;
    top: 0;

    &:before {
      content: none;
    }
  }

  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }
  .carousel {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    &.slick-initialized {
      visibility: visible;
      opacity: 1;
    }
  }
  .slick-disabled {
    opacity: 0.5;
    svg {
      opacity: 0;
    }
  }
}

@mixin slickdots {
  .carousel {
    margin-bottom: 0;
  }
  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-dots {
    bottom: 50px;
    left: 0;
    width: auto;
    @include responsivemd {
      bottom: 0px;
    }
    @include responsivesm {
      bottom: -60px;
      width: 100%;
      margin: auto;
    }

    button {
      // display: none;
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    li {
      height: 10px;
      width: 10px;
      border-radius: 50px;
      transition: 0.3s linear;
      transition-delay: 0.3s;
      border: 3px solid $secondary-color;
      position: relative;
      opacity: 0.2;
      position: relative;
      // &:after {
      //   content: "";
      //   position: absolute;
      //   height: 12px;
      //   width: 14px;
      //   top: 2px;
      //   left: -4.5px;
      //   border: 3px solid #00aae7;
      //   border-radius: 27px;
      //   clip-path: polygon(30% 50%, 70% 50%, 100% 100%, 0% 100%);
      //   opacity: 0;
      //   @include transition-out;
      //   transform-origin: center 0px;
      // }
      &.slick-active {
        opacity: 1;
        @include responsivemd {
          background-color: $secondary-color;
        }
      }
    }
  }
  .slick-slide.slick-active {
    transform: translateY(0);
    .card__img {
      transform: translateX(0px);
      &::after {
        transform: scaleX(0);
      }
    }
  }
  .item {
    .card__img {
      transform: translateX(60px);
      transition: 0.4s 0.5s linear;

      &:after {
        content: "";
        @include position;
        max-width: 200px;
        background-color: $primary-color;
        transform-origin: center right;
        transition: 0.6s 0.5s linear;
      }
    }
  }
}

@mixin slickGrab {
  .slick-list {
    position: relative;
  }
}

@mixin active {
  &.active {
    color: #fff;
    background-color: $red;
    @include transition-in;
  }
}
@mixin mt {
  margin-top: 30px;
}

@mixin nav__tabs {
  .nav-tabs {
    @include card__margin;
    border: 0;
    position: relative;
    background-color: #fff;
    align-items: center;
    // @include scrollbar;
    .logo img {
      height: 40px;
      width: auto;
      position: relative;
    }
    .nav-item {
      margin: 0;
      &:first-child {
        .nav-link {
          padding-left: 0;
        }
      }
    }
    .nav-link,
    button {
      font-size: 16px;
      border: 0;
      padding: 40px 20px;
      color: $primary-color;
      position: relative;
      font-weight: 500;
      opacity: 1;
      min-width: none;
      min-width: auto;
      font-family: "Poppins", sans-serif;
      text-transform: capitalize;
      @include responsivemd {
        padding: 20px 8px;
        font-size: 14px;
        opacity: 0.7;
      }
      &.active,
      &.Mui-selected {
        color: $secondary-color;
        @include responsivemd {
          background-color: #f2f2f2;
          border-radius: 10px;
          color: $primary-color;
          opacity: 1;
        }
      }
      &.aside--left {
        @include responsivemd {
          display: none;
        }
      }
    }
    .PrivateTabIndicator-colorSecondary-3 {
      display: none;
    }
  }
}

button.white {
  @include button;
  background-color: #fff;
  color: $dark;
  &:hover {
    color: $primary-color;
    @include box-shadow;
  }
  &.active {
    @include box-shadow;
  }
}
@mixin clients--card {
  // width: calc(100% - #{$common-left});
  width: calc(100% - 590px);
  max-width: 100%;
  margin: 0;
  // margin: 0 auto;
  margin-left: 450px;
  text-align: left;
  @include responsivemd {
    margin-left: 0;
    width: 100%;
  }
}
@mixin tags {
  .tags {
    background-color: rgba($primary-color, 0.06);
    color: $primary-color;
    padding: 6px 12px;
    font-size: 14px;
    margin: 0px 1px 12px 0;
    border-radius: 5px;
    font-weight: 600;
    // transition: none;
    &:hover {
      box-shadow: rgba(242, 101, 34, 0.1) 0px 3px 0px;
    }
    &:last-child {
      margin: 0;
    }
  }
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

@mixin card {
  .card,
  .card__horizontal {
    border: 2px solid rgba(0, 170, 231, 0.2);
    box-shadow: 0;
    font-size: 18px;
    background-color: transparent;
    position: relative;
    z-index: 1;
    padding: 15%;
    @include responsive(1550px) {
    }
    @include transition-in;
    @include border-radius;

    img {
      @include image;
    }
    .card__img {
      height: 100px;
      overflow: hidden;
      margin-bottom: 20px;
      position: relative;
    }

    .card__title {
      font-size: 24px;
      line-height: 1.3;
      font-weight: 600;
      margin: 0;
      margin-bottom: 10px;
      @include responsivemd {
        // font-size: 18px;
      }
    }
    .card__body {
      padding: 0;
    }
    .small__title {
      color: rgba($para, 0.8);
      display: block;
      font-size: 17px;
      font-style: normal;
      margin-bottom: 8px;
    }
    time {
      color: rgba($para, 0.5);
      font-size: 14px;
    }

    button {
      @include button;
    }
    .para {
      // color: rgba(76, 77, 79, 0.8);
      font-size: 16px;
      // line-height: 1.5;
    }

    .text-muted {
      color: rgba($para, 0.2) !important;
    }
  }
}

@mixin article {
  .row {
    margin: 0 -15px;
    margin-bottom: 15px;
    @include responsivemd {
      margin: 0 -10px;
      margin-bottom: 10px;
    }
    & > article {
      padding: 0 15px;
      @include responsivemd {
        padding: 0 10px;
      }
    }
  }
}

@mixin carousel__item {
  .slick-list {
    margin: 0 -15px;
    .item {
      padding: 15px;
    }
  }
}

@mixin aside {
  .aside--left {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: space-between;
    align-items: flex-start;
    @include responsivemd {
      flex-grow: 0;
    }
  }

  .aside--right {
    width: calc(100% - 450px);
    min-width: calc(100% - 450px);

    position: relative;
    max-width: 100%;
    @include responsivetb {
      width: calc(100% - 300px);
      min-width: calc(100% - 300px);
    }
    @include responsivemd {
      width: 100%;
    }
  }
}

@mixin card-hr {
  .card__hr {
    flex-direction: row;
    .card__img {
      height: 150px;
      overflow: hidden;
      width: 150px;
      border-radius: 50%;
      margin-right: 2em;
      @include responsivemmd {
        height: 100px;
        width: 100px;
        margin-right: 1em;
      }
      @include responsive(360px) {
        height: 70px;
        width: 70px;
        margin-right: 10px;
      }
    }
    .card__title {
      margin: 0 !important;
      width: 240px;
      @include responsivemd {
        width: 100%;
      }
    }
    .price {
      margin: 0 !important;
    }
    .card__body {
      text-align: left;
      display: flex;
      flex-grow: 2;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 100px);

      @include responsivemd {
        flex-wrap: wrap;
      }
      @include responsive(360px) {
        font-size: 12px;
      }
      .qty {
        @include responsivemd {
          margin-right: 0;
        }
      }
      .qty button,
      .qty .form-control {
        @include responsivemd {
          width: 50px;
          height: 50px;
        }
        @include responsivesm {
          width: 30px;
          height: 30px;
          line-height: 1;
        }
      }
    }
    i {
      vertical-align: middle;
      columns: $lite;
    }
  }
}

@mixin dur {
  .duration {
    background-color: $para;
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 13px;
    border-radius: 5px 0 0 0;
    padding: 0px 8px;
    line-height: 1.6;
  }
}

@mixin modal {
  border-radius: 20px;
  border: 0;
  padding: 50px;
  position: relative;
  background-color: #fff;
  @include responsive(1550px) {
    padding: 35px;
  }

  .modal-title {
    margin-bottom: 20px;
  }
}

@mixin cardhr--scroll {
  .row {
    @include responsivelg {
      @include scrollbar;
    }
    & > article {
      @include responsivelg {
        max-width: 100%;
        flex-basis: 30%;
        scroll-snap-align: end;
      }
      @include responsivemd {
        flex-basis: 40%;
      }
    }
    .card {
      @include responsivelg {
        white-space: normal;
      }
    }
  }
}

@mixin boxForm {
  .list-group {
    display: grid;
    grid-gap: 20px;
    grid-template: auto / repeat(3, 1fr);
    .form-check {
      padding: 0;
    }
    @include responsivemd {
      grid-template: auto / repeat(1, 1fr);
      gap: 12px;
    }
    .list-group-item {
      border: 0;
      padding: 1em;
      font-size: 17px;
      font-weight: 500;
      color: $para3;
      @include border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 150px;
      background-color: #f2f2f2;
      cursor: pointer;
      @include transition-in;
      @include responsive(1550px) {
        height: 130px;
      }
      @include responsivemd {
        height: 60px;
        justify-content: flex-start;
        text-align: left;
      }
    }
    input {
      height: 0;
      width: 0;
      opacity: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      &:checked + label {
        background-color: $secondary-color;
        color: #fff;
        @include transition-out;
      }
    }
  }
}
