@import "./../custom.scss";

.bg--primary {
  background-color: $primary-color !important;
}

.border-left {
  display: block;
  height: 60px;
  width: 1px;
  background: $grey;
}

.button-outer {
  position: absolute;
  top: 0;
  right: -100px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  @include responsivemd {
    position: relative;
    right: 0;
    flex-direction: row-reverse;
    order: -1;
    width: 100%;
    padding: 20px;
  }
  button {
    padding: 0;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: transparent;
    margin-bottom: 30px;
    @include responsivemd {
      border-color: $para3;
      height: 42px;
      width: 42px;
      margin-bottom: 0;
      margin-left: 15px;
      svg {
        height: 18px;
        width: auto;
        path {
          stroke: $para3;
        }
      }
    }
    &.active {
      background-color: #fff;
    }
    &.close {
      transition: none;
      &:hover {
        svg {
          @include transition-in;
          transform: rotate(90deg);
        }
      }
    }
  }
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid rgba($dark, 0.8);
  background-color: transparent;
  pointer-events: none;
  transition: linear;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: opacity 0.6s ease-in, transform 0.6s ease-in;
}

.carousel--wrapper:hover {
  // cursor: grab;
  .cursor {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.8);
  }
}

.card__margin {
  @include card__margin;
}

.client__card {
  img {
    @include image;
  }
  .card__bottom {
    display: flex;
    align-items: center;
    @include card__margin;

    .card__title {
      margin-bottom: 0;
    }
  }
  .card__top {
    display: flex;
    align-items: center;
    // margin-bottom: $common-margin--px;
    margin-bottom: 10px;

    svg {
      height: 100px;
      width: auto;
      min-width: 50px;
      @include responsivesm {
        height: 50px;
        min-width: 20px;
        transform: none !important;
      }
    }
  }
  .card__img {
    width: 100px;
    height: 100px;
    margin-right: 0.6em;
    min-width: 100px;
    border-radius: 50%;
    overflow: hidden;
    transform: none !important;
    &:after {
      background-color: #f2f2f2 !important;
      max-width: 100% !important;
      transform-origin: center left !important;
    }
    @include responsivesm {
      height: 50px;
      width: 50px;
      min-width: 50px;
    }
  }
  .card__title {
    color: #828282;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: $common-margin--px;
    // width: 500px;
    max-width: 100%;
    @include responsivemd {
      font-size: 17px;
      margin-bottom: 30px;
    }
    @include responsivesm {
      font-size: 16px;
    }
  }
  .para {
    color: #cacaca;
    font-size: 16px;
    @include responsivesm {
      font-size: 14px;
    }
    // font-weight: 600;
  }
}

.grid {
  display: grid;
  grid-gap: 30px;
  grid-template-rows: auto;
  @include responsivemd {
    grid-gap: 15px;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.modal--projects {
  background: rgba(0, 0, 0, 0.7);
  @include responsivemd {
    background-color: $lite;
  }
  .MuiSkeleton-rect,
  .MuiSkeleton-text {
    background-color: #8b8b8b;
    @include border-radius;
  }
  .loader--box {
    @include responsivemd {
      padding: 20px;
    }
  }
}
.MuiPopover-root {
  h3.title--small {
    color: $para3;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

.price {
  color: #ff4c4c;
  font-weight: 700;
}

.para {
  line-height: 1.5;
  font-size: 16px;
  margin-bottom: 0;
  color: $para;
  @include responsivemd {
    font-size: 16px;
  }
  // @include responsivesm {
  //   font-size: 1px;
  // }
}

.section__title {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  color: $primary-color;
  z-index: 1;
  @include responsivemd {
    font-size: 38px;
  }
  @include responsivesm {
    font-size: 36px;
  }
}

.section__title.white {
  color: #fff;
}

.section__title--sm {
  font-size: 30px;
  margin-bottom: calc(10vh - 6px);
  line-height: 1.1;

  &.white {
    color: #fff;
  }
  @include responsivesm {
    font-size: 22px;
  }
}
.section__title--md {
  font-size: 48px;
  margin-bottom: 1em;
  line-height: 1.3;
  color: $primary-color;
  font-weight: 700;
  &.white {
    color: #fff;
  }
  @include responsivesm {
    font-size: 28px;
    margin-bottom: 0.6em;
  }
}

.section__title--lg {
  min-height: 180px;
  font-size: 200px;
  opacity: 0.03;
  position: absolute;
  transform: rotate(-90deg) translateY(40px);
  transform-origin: left;
  top: 265px;
  z-index: 2;
  @include responsivemd {
    min-height: 80px;
    font-size: 90px;
    transform: rotate(-90deg) translateY(30px);
  }
  @include responsivesm {
    font-size: 60px;
    position: relative;
    transform: none;
    height: auto;
    // min-height: 10px;
    top: 0;
    margin-top: 0.8em;
    opacity: 0.1;
  }
}

.section__link {
  color: $white;
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;
  svg {
    fill: $white;
    margin-right: 1rem;
    height: 12px;
    width: auto;
    vertical-align: baseline;
    @include transition-in;
    overflow: visible;
  }
  .g {
    path {
      @include transition-in;
    }
    path:first-child {
      // transform: rotate(0deg);
      transform: translateX(0);
      // transform-origin: 8px center;
    }
  }
  &:hover {
    .g {
      path {
        fill: $primary-color;
      }
      path:first-child {
        // transform: rotate(360deg);
        transform: translateX(4px);
        fill: $secondary-color;
      }
    }
  }
}

.screen--logo {
  width: auto;
  height: 60px;
  margin-bottom: $common-margin;
}
.shortcut {
  display: flex;
  justify-content: center;
  kbd {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: #bdbdbd;
    font-size: 16px;
    font-family: Poppins;
    margin: 0 10px;
    .box {
      width: 32px;
      height: 30px;
      border: 1px solid #bdbdbd;
      box-sizing: border-box;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      display: block;
      text-align: center;
      margin: auto;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 28px;
    }
  }
}

.text--secondary {
  color: $secondary-color;
}

.trails-text > div {
  overflow: hidden;
}
.title--small {
  font-size: 28px;
  @include responsivemd {
    font-size: 18px;
  }
}
.text__active {
  color: $primary-color;
}

.title__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .section__title {
    margin-bottom: 0 !important;
  }
}
.text-outline {
  -webkit-text-stroke: 2px $primary-color;
  // -webkit-text-fill-color: transparent;
  // opacity: 0.1;
  font-weight: bold;
  // background-clip: text;
  // background-repeat: no-repeat;
  // background-size: 0 100%;
  @include transition-in;
  display: inline-block;
  opacity: 0.1;
  color: transparent;
  &:hover {
    @include transition-out;
    // background-image: linear-gradient(to right, $primary-color, $primary-color);
    color: $primary-color;

    // background-size: 100% 100%;
  }
}
.text-outline--font {
  font-size: 90px;
  @include responsivesm {
    font-size: 60px;
  }
}
.text-extreme-lg {
  font-size: clamp(6rem, 15vw, 200px) !important;
}

.toast {
  position: fixed;
  bottom: 10px;
  left: 10px;
  padding: 10px;
  border-radius: 20px;
  background-color: #e35d6a;
  &.success {
    background-color: #53b866;
  }
  .close {
    color: #fff;
    margin-bottom: 0 !important;
  }
}

.vh {
  height: 100vh;
  max-height: 1080px;
  @include responsivemd {
    height: 100%;
    max-height: 100%;
  }
}

.empty {
  // background-color:transparent;
  // pointer-events: none;
  opacity: 0;
}

.MuiFormLabel-root {
  &.Mui-focused {
    color: $secondary-color !important;
  }
}

.jss3 {
  display: none !important;
}

.loader--video {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

video {
  pointer-events: none;
  // background-color: #f2f2f2;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.section__title--lg__wrapper {
  position: sticky;
  top: -140px;
  @include responsive(1600px) {
    top: -240px;
  }
  height: 100vh;
  max-height: 1080px;
  @include responsivemmd {
    position: relative;
    top: 0;
    height: auto;
  }
}

main,
header,
footer,
.hamburger {
  @include transition-in;
  opacity: 1;
  transition-delay: 0.3s;
  &.opacity {
    opacity: 0;
    transition: none;
    transform: translateY(30px);
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $secondary-color;
  z-index: 999;
  width: 100%;
  height: 110vh;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);
  opacity: 0;
  pointer-events: none;
  &:not(.bottom).active {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(-110%);
    transition: transform 1s 0.3s ease-in;
  }
  &.bottom {
    transform: translateY(110%);
    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0%);
      transition: transform 0.6s 0.3s cubic-bezier(0, 0.96, 0.32, 0.8);
    }
  }
}

.loader--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  svg {
    margin-right: 0 !important;
  }
  .MuiCircularProgress-root {
    height: 30px;
    width: 30px;
    color: $secondary-color;
  }
  &:hover {
    .MuiCircularProgress-root {
      color: #fff;
    }
  }
}

.fixed--image {
  position: fixed;
  height: 100%;
  width: auto;
  right: 0;
  top: 0;
  opacity: 0.3;
  @include responsivemd {
    height: 100vh;
    width: 100vw;
    object-fit: contain;
    left: 8em;
  }
}

footer,
#roadmap {
  transition: 0.3s 0.45s ease-in;
  &.fadein {
    opacity: 0.1;
    transition: 0.35s 0s linear;
  }
}

.btn-slide {
  width: 120px;
  overflow: hidden;
  position: relative;
  text-align: left;
  white-space: nowrap;
  @include responsivesm {
    width: 250px;
  }
  &,
  span {
    @include transition;
  }
  .hidden {
    // position: absolute;
    // transform: translateX(40px);
    white-space: nowrap;
    opacity: 0;
    // opacity: 0;
    @include responsivesm {
      opacity: 1;
    }
  }
  .fire,
  .ice {
    path {
      stroke-width: 0 !important;
    }
  }
  &:hover {
    width: 250px;

    // width: max-content;
    // .unhidden {
    //   opacity: 0;
    //   margin-left: -80px;
    // }
    // @include responsivesm {
    //   width: 120px;
    // }
    .hidden {
      // transform: translateX(60px);
      opacity: 1;
      // @include responsivesm {
      //   opacity: 0;
      // }
    }
    .fire,
    .ice {
      path {
        fill: #fff;
      }
    }
  }
}
body {
  address {
    margin-bottom: 0;
  }
}

.button--group {
  display: flex;
  gap: 1.6em;
  @include responsivemd {
    flex-wrap: wrap;
    gap: 0.6em;
  }
}

.desktop--only {
  @include responsivesm {
    display: none;
  }
}
.mobile--only {
  @include responsivemin(600px) {
    display: none;
  }
}

img[src$=".jpg"] {
  background-image: url("../../images/dg_Placeholder.png");
  color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

.testi--skeleton {
  .d-flex {
    margin-top: 30px;
    align-items: center;
  }
  .circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    min-width: 100px;
    margin-right: 1em;
    @include responsivesm {
      height: 50px;
      width: 50px;
    }
  }
  .text {
    height: 40px;
    width: 50%;
    min-width: 200px;
    @include border-radius;
  }
}
