@import "../custom";
@import "./component";

.form-control[readonly] {
  background-color: transparent;
}

// main menu
header {
  font-size: 1rem;
  font-weight: 500;
  @include responsivemd {
    background-color: #fff;
  }
  &.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .section__rule {
    padding-bottom: 0;
    @include responsivemd {
      padding: 20px 0;
    }
  }
  &.white {
    color: #fff;
    .nav-link,
    .nav-link:hover {
      &:before {
        background-color: rgba(255, 255, 255, 0.8);
      }
      color: #fff;
    }
    .logo img {
      opacity: 0;
      @include responsivemd {
        opacity: 1;
      }
      &.logo-white {
        opacity: 1;
        @include responsivemd {
          display: none;
        }
      }
    }
  }

  .nav {
    align-items: center;
    padding: 0;
  }

  .nav-item {
    span {
      display: block;
      transform: matrix(1, 0, 0, 1, 0, 0);
      @include transition;
      // pointer-events: none;
    }
    &.hover {
      span {
        transition-duration: 0.1s !important ;
      }
    }
    &:not(.hover) {
      span {
        transform: none !important;
      }
    }
  }
  .nav-link {
    color: $dark;
    position: relative;
    padding: 24px;
    margin: 0;
    overflow: hidden;
    @include responsivemd {
      display: none;
    }
  }
  .grow {
    flex-grow: 2;
  }
  .logo a {
    height: 40px;
    width: auto;
    position: relative;
    @include responsivemd {
      height: auto;
    }
  }

  .logo img {
    fill: $primary-color;
    height: 40px;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s linear;
    @include responsivemd {
      height: 30px;
      position: relative;
    }
    &.logo-white {
      opacity: 0;
      @include responsivemd {
        display: none;
      }
    }
  }
}
.navigation {
  @extend header;
  font-size: 22px;
  font-weight: lighter;
  position: fixed;
  top: 0;
  left: 102%;
  height: 100vh;
  width: 100vw;
  z-index: 10;

  @include responsivemd {
    font-size: 16px;
  }
  transition: 0.6s ease-out;

  transition-delay: 0.3s !important;
  &.active {
    left: 0%;
    .section__rule {
      &:after {
        height: 100%;
        transition-delay: 0.7s;
        animation: line 4s cubic-bezier(0.77, 0, 0.18, 1) 2s infinite forwards;
      }
    }
    .right {
      .nav-item {
        transform: translateX(0);
        opacity: 1;
        transition-delay: 0.6s;
        transition: 0.6s ease-in;
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            transition-delay: $i/30 + 0.6s;
          }
        }
      }
    }
  }
  &.margin__right + .hamburger.active {
    @include responsivemin(840px) {
      right: calc(17px + 5%);
    }
  }
  .animate__animated {
    animation-delay: 3s;
    transition-delay: 0.7s;
  }
  .section__rule {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      height: 0%;
      width: 2px;
      left: 50%;
      transform: translateX(-50%);
      // background-color: rgba($secondary-color, 0.1);
      transition: 0.6s ease-in;
      background: linear-gradient(
        180deg,
        rgba($secondary-color, 0.1) 90%,
        transparent 90%
      );
      background-position-y: 0;
      @include responsivemd {
        height: 100vw !important;
        left: 163px;
        transform: rotate(90deg) translateX(30vh);
        transform-origin: left center;
      }
    }

    & > .row {
      height: 100%;
      align-content: center;
    }
  }
  .left {
    display: flex;
    align-items: center;
    @include responsivemd {
      order: 1;
      justify-content: center;
      text-align: center;
    }
    .section__title {
      margin-bottom: calc(#{$common-margin} - 30px);
    }
    button {
      @include button;
    }
  }
  .right {
    .nav {
      align-items: flex-start;
      width: min-content;
      margin: auto;
      @include responsivemd {
        margin-left: 0;
        margin-bottom: 60px;
      }
      &:hover {
        .nav-link {
          opacity: 0.3;
        }
      }
    }
    .nav-item {
      @include transition-in;
      transform: translateX(200px);
      opacity: 0;
    }
    .nav-link {
      color: #fff;
      padding: 6px;
      font-weight: 500;

      @include responsivemd {
        display: inline-block;
      }

      @include transition;

      &:hover {
        opacity: 1 !important;
        //    font-weight: 500;
      }
      &.active {
        // color: $primary-color;
        opacity: 1;
        font-weight: 600;
        font-weight: bold;
      }
    }
    .nav-link--lg {
      font-size: 40px;
      @include responsivemd {
        font-size: 28px;
      }
    }
  }
}

.hamburger {
  display: flex;
  position: fixed;
  z-index: 15;
  top: calc(50vh);
  right: 5%;
  & > div {
    transition: 0.1s linear;
    position: relative;
  }
  &:not(.hover) > div {
    transform: none !important;
  }
  @include transition-in;
  transition-delay: 0s;
  justify-content: center;
  height: 50px;
  width: 50px;
  @include transition;
  // border:1px solid transparent !important;
  border-radius: 50%;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  gap: 1em;
  @include responsivemd {
    top: 15px;
    transform: rotate(90deg);
  }

  @include responsive(1925px) {
    right: 5%;
  }
  &:hover {
    .text {
      transform: rotate(-90deg) translateX(-12px);
    }
  }
  .text-wrapper {
    position: absolute;
    // background-color: red;
    height: 50px;
    overflow: hidden;
    bottom: 100%;
    left: -14px;
    @include responsivemd {
      display: none;
    }
  }
  .text {
    transform: rotate(-90deg) translateX(-62px);
    @include transition;
    display: block;
    color: $dark;

    // opacity: 0;
  }
  .burger-line {
    display: flex;
    @include transition-in;
  }

  span {
    display: block;
    height: 25px;
    width: 2px;
    background-color: $dark;
    margin: 0 3px;
    @include transition-in;

    &:last-child {
      transform-origin: -3px 9px;
    }
  }
  .hide {
    display: none;
  }

  &.active {
    .unhide {
      display: none;
    }
    .hide {
      display: inline-block;
    }
    .burger-line {
      transition-delay: 0.3s;
      transform: rotate(135deg) translateX(3px);
    }
    @include responsivemd {
      transform: rotate(90deg);
    }
    .text {
      color: #fff;
    }
    span {
      background-color: #fff !important;
      @include transition-out;

      &:last-child {
        transform: rotate(90deg);
      }
    }
  }
  &.white {
    &,
    .text {
      color: #fff;
    }
    span {
      background-color: #fff;
      @include responsivemd {
        background-color: $dark;
      }
    }
  }
}

.primary-header {
  .nav-link {
    &:before {
      content: "";
      position: absolute;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: $secondary-color;
      bottom: 1em;
      left: 50%;
      transform: scale(0);
      @include transition;
    }
    &.active:before {
      transform: scale(1);
    }
  }
}
