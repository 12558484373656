footer {
  font-size: 1rem;
  font-weight: 500;
  background-color: transparent;

  @include responsivemd {
    // background-color: $primary-color;
  }
  @include transition-in;
  transition: 0.3s 0.45s ease-in;

  position: relative;
  @include responsivesm {
    // background-color: $primary-color;
  }
  &.active {
    // background-position-y: 0;
    .footer--bg {
      transition: height 0.6s 0.5s cubic-bezier(0, 0.96, 0.32, 0.8);
      height: 100%;
    }
  }
  .footer--bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $primary-color;
    transition: height 0.6s 0s cubic-bezier(0.39, 0.59, 0.49, 1.07);
    z-index: 0;
    @include responsivesm {
      // transition-timing-function: linear;
      // transition-delay: 0.3s;
      // height: 100%;
      // display: none;
      background-color: $primary-color;
    }
  }
  .vh {
    @include responsivemd {
      height: calc(100vh - 70px);
    }
  }
  &:not(.active) {
    // @include responsivemin(600px) {
    .section__title.white,
    .para,
    .footer--right button {
      color: #f2f2f2;
    }
    svg,
    svg path {
      fill: #f2f2f2;
    }
    // }
  }
  .section__title.white,
  .para,
  svg,
  svg path {
    transition: 0.6s 0.3s ease-out;
  }
  .section__rule {
    position: relative;
    z-index: 1;
    & > .d-flex {
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 160px;
      @include responsivemin(1700px) {
        padding-top: 0;
      }
      @include responsivemd {
        padding: 0px 0;
      }
      & > .d-flex {
        align-items: center;
        flex-grow: 2;
        @include responsivemd {
          flex-direction: column-reverse;
          align-items: flex-start;
          justify-content: space-around;
        }
      }
    }
  }

  * {
    z-index: 1;
  }

  @include aside;
  .footer--right {
    button {
      @include button;
    }
    @include responsivemd {
      margin-bottom: 50px;
    }
  }
  .section__title {
    min-height: 180px;
    @include responsivemd {
      min-height: 120px;
    }
  }

  .footer--left {
    .nav {
      @include responsivemd {
        display: none;
      }
    }
  }

  .nav {
    &.flex-column {
      @include responsivemd {
        flex-direction: row !important;
      }
    }
    .nav-link {
      color: #fff;
      margin: 16px 0;
      position: relative;
      block-size: 30px;
      padding: 0;
      @include responsivemd {
        margin: 0;
        margin-right: 50px;
        margin-block: 50px;
      }
      svg {
        height: 100%;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        @include transition-in;
        height: 26px;
        width: auto;
        &:not(.active) {
          opacity: 0;
        }
      }
      &:hover {
        svg {
          opacity: 1;
          &.active {
            opacity: 0;
          }
        }
      }
    }
  }

  .footer--bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include responsivemd {
      flex-direction: column-reverse;
      align-self: flex-start;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .copyright {
      // flex-grow: 2;
      width: 450px;
      margin-bottom: 0;
      max-width: 100%;
      @include responsivesm {
        width: 100%;
        font-size: 14px;
      }
    }
    .emailus {
      margin-bottom: 0;
      flex-grow: 2;
      margin-top: 2em;
    }
    & > p {
      @include responsivemd {
        // margin-top: 3em;
      }
    }
    .nav {
      @include responsivemin(760px) {
        display: none !important;
      }
    }
    address {
      .para {
        @include responsivemd {
          font-size: 20px;
        }
        @include responsivesm {
          font-size: 18px;
        }
      }
    }
  }

  .para {
    color: $para !important;
    text-decoration: none;
  }
  .uiux {
    @include responsivesm {
      position: absolute;
      transform: rotate(-90deg);
      bottom: 180px;
      right: 30px;
      transform-origin: center right;
    }
  }
}
