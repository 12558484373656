@import "./component_css/header";

.other-page {
  .bg--light {
    background-color: #f2f2f2;
    position: relative;
  }

  .clients {
    .section__rule {
      & > .d-flex {
        justify-content: center;
        height: 100%;
        flex-direction: column;
        position: relative;
      }
    }
    .client__card--wrapper {
      @include clients--card;
    }
    .vh {
      @include responsivemd {
        height: 100%;
        padding: 100px 0;
      }
      @include responsivesm {
        // padding: 50px 0;
      }
    }
  }
}

.about-us-page {
  .screen--template {
    &,
    .section__rule {
      padding-top: 0 !important;
      @include responsivemd {
        padding-bottom: 40px;
        // height: calc(100vh - 20px);
        min-height: calc(100vh - 200px);
      }
    }

    video {
      height: 100vh;
      max-height: 1080px;
      @include responsivemd {
        height: 500px;
      }
    }
  }
  .project--template {
    .card {
      background-color: #f2f2f2;
      overflow: hidden;
      transition: none;
      &:hover {
        @include box-shadow;
      }
      .card__body {
        padding: 50px 40px;
        padding-bottom: 0;
        @include responsivemd {
          padding: 25px;
        }
      }
      .card__title {
        color: $para2;
        font-weight: 700;
      }
      .para {
        color: #828282;
        font-size: 18px;
        @include responsivemd {
          // font-size: 16px;
        }
      }
      .deg {
        font-size: 30px;
        opacity: 1;
        position: absolute;
        transform: rotate(-90deg) translate(-100%, 15px);
        transform-origin: left;
        font-weight: 700;
        color: #e0e0e0;
        text-align: center;
        z-index: 2;
        margin: 0;
        white-space: nowrap;
        @include responsivemd {
          // font-size: 28px;
          transform: rotate(-90deg) translate(-100%, 8px);
        }
        &.left {
          // @include responsivesm {
          transform: rotate(-90deg) translate(-100%, 25px);
          // }
        }
      }
      .card__img {
        height: auto;
        margin-bottom: 0;
        overflow: visible;
        img {
          width: 100%;
          height: 350px;
          object-position: 35px top;
          transition: none;
          z-index: 2;
          position: relative;
          @include responsivemd {
            // height: 250px;
          }
          // @include transition-in;
        }
      }
    }
    .section__title {
      @include responsivesm {
        display: flex;
        flex-wrap: wrap;
      }
      span:first-child {
        @include responsivesm {
          padding-right: 7px;
        }
      }
    }
  }
  .clients {
    .section__title--lg {
      @include responsivemd {
        // transform: translateY(-200px);
        // height: 0px;
      }
    }
    .section__title--sm {
      display: flex;
      line-height: 1.4;
      transform: translateY(4em);
      margin-bottom: 0;
      max-width: 444px;
      @include responsivesm {
        flex-wrap: wrap;
        transform: translateY(0em);
        margin-top: 2em;
      }
      .quote {
        font-size: 50px;
        margin-right: -20px;
        transform: translateX(-1.5em);
        @include responsivesm {
          margin-right: 1em;
          transform: none;
        }
      }
    }
    .card__title {
      color: #424242;
    }
    .client__card {
      width: 470px;
      max-width: 100%;
      @include responsivemd {
        width: 100%;
      }
    }
  }
}

.projects-page {
  .screen--template {
    @include responsivemd {
      // display: none;
    }
    .section__rule {
      @include responsivemin(1700px) {
        padding-bottom: 0 !important;
      }
    }
    img {
      height: 50vh;
      max-height: 500px;
      min-height: 300px;
    }
  }

  .project--template {
    position: relative;
    // z-index: 9;
    // @include responsivemd {
    //   z-index: 8;
    // }
    @include nav__tabs;
    .section__rule {
      position: relative;
      z-index: 9;
      @include responsivemin(1700px) {
        padding-top: 0 !important;
      }
      @include responsivemmd {
        z-index: 1;
        padding-top: 0;
      }
    }
    .nav-tabs {
      position: sticky;
      z-index: 9;
      top: -190px;
      width: calc(100% - 180px);
      margin-left: auto;
      background-color: #fff;
      flex-wrap: nowrap;
      @include responsive(1556px) {
        top: -100px;
      }
      @include responsivemd {
        width: 100%;
        // position: relative;
        top: 70px !important;
        padding-bottom: 10px;
        background-color: #fff;
      }
      @include transition-out;
      background-color: transparent;
      button {
        padding-top: 140px;
        @include responsive(1560px) {
          padding-top: 80px;
        }
        @include responsivemd {
          padding: 8px 12px;
        }
        .MuiTouchRipple-root {
          display: none;
        }
      }

      &.sticky {
        top: 0px;
        @include transition-in;
        @include responsive(1560px) {
          // top: 20px;
        }
      }

      .MuiTabs-root {
        background-color: #fff;
        .MuiTabs-flexContainer {
          justify-content: flex-end;
          @include responsivemd {
            justify-content: flex-start;
          }
          button:first-child {
            @include responsivemin(600px) {
              color: #ff543e;
              order: 1;
              @include transition-out;
              overflow: hidden;
              transform: translateX(0px);
              transform-origin: right;
              &.Mui-selected {
                width: 0px;
                padding-inline: 0;
                @include transition-in;
              }
            }
          }
        }
      }
      .aside--right {
        width: 100%;
      }
    }
    a {
      text-decoration: none;
      display: block;
    }
    .card {
      background-color: #fff;
      overflow: hidden;
      box-shadow: 0px 0px 20px rgba(3, 37, 108, 0.05);
      animation: fade 0.6s linear 0s 1 forwards;

      &:hover {
        img {
          transform: scale(1.05);
        }
      }
      .card__body {
        padding: 30px 40px;
        padding-top: 0;
        min-height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include responsivemd {
          padding: 25px;
          min-height: auto;
        }
      }
      .card__title {
        color: $primary-color;
        font-weight: 500;
      }

      .para {
        color: #bdbdbd;
        font-size: 17px;
        margin-bottom: 12px;
      }

      .card__img {
        height: 220px;
        background-color: $primary-color;
        img {
          @include transition-in;
          background-image: url("../images/dg_Placeholder.png");
          color: transparent;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    a.section__link {
      margin: 60px auto 30px auto;
      display: flex;
      align-items: center;
      width: max-content;
    }
    .aside--left {
      @include responsivemd {
        display: none;
      }
    }
  }
}

.expertise-page {
  .expertiseHeader {
    .section__rule {
      overflow-x: scroll;
      @include scrollbar;
      padding: 100px 5vw;
      scroll-behavior: smooth;
      h2 {
        padding: 0 0.1em;
      }
      @include responsivemd {
        padding: 30px 5%;
      }
    }
    .expertiseHeader__text:hover svg path {
      fill: $primary-color;
      @include transition-out;
    }
    svg {
      width: auto;
      height: 90px;
      opacity: 0.1;
      @include responsivemd {
        height: 50px;
      }

      path {
        fill: transparent;
        stroke-width: 1.5px;
        @include transition-in;
      }
    }
  }

  .screen--template {
    height: auto;
  }

  .roadmap__wrapper {
    padding: 60px 0;
    @include responsivemd {
      padding: 20px 0;
    }
    .aside__right__top {
      display: flex;
      align-items: flex-end;
      img {
        margin-right: 26px;
        height: 50px;
        width: auto;
        @include responsivemmd {
          height: 38px;
        }
      }
      .section__title {
        font-size: 40px;
        margin-bottom: 0;
        @include responsivemmd {
          font-size: 30px;
        }
      }
    }

    .item {
      max-width: 570px;
    }

    .card__title {
      font-size: 20px;
      margin: 30px 0;
      @include responsivemd {
        font-size: 18px;
      }
    }

    ul {
      @include ul;
      color: #828282;
      line-height: 2.5;
      font-size: 18px;
      i {
        color: $secondary-color;
        font-style: normal;
        margin-right: 6px;
      }
    }
  }

  .roadmap {
    .aside--left__top {
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
    }

    .progress {
      position: sticky;
      height: calc(80vh - 20px);
      top: calc(10vh + 20px);
      width: 2px;
      max-height: calc(1080px -10vh - 20px);
      margin-left: 270px;
      .progress-bar {
        background-color: $secondary-color;
        min-height: 200px;
        width: 100%;
        height: 0%;
        transition: none !important;
      }
    }

    .section__title--lg__wrapper {
      @include responsivemin(1600px) {
        top: 60px;
      }
    }
    .aside--left {
      @include responsivemd {
        display: none;
      }
    }
  }
}

.modal--projects {
  $modal--p: 35px;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  overflow-y: scroll;
  &.opacity {
    opacity: 0 !important;
  }
  .slick-arrow {
    opacity: 0;
    @include transition;
  }
  @include slicknavnormal;
  opacity: 0;
  animation: fade 0.3s linear 0s 1 forwards;
  .section__rule {
    position: relative;
    @include responsivemd {
      width: 100%;
      padding: 0;
    }
  }
  .modal--main {
    @include modal;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $modal--p;
    @include responsive(1600px) {
      width: calc(100% - 60px);
    }
    @include responsivemd {
      padding: 0;
      border-radius: 0;
      margin-bottom: 0;
      width: 100%;
    }
    .aside__left {
      flex-grow: 2;
      width: calc(100% - 470px);
      position: relative;
      @include responsivemd {
        width: 100%;
      }
    }
    .aside__right {
      width: 380px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 50px;
      @include responsive(1550px) {
        padding-left: $modal--p;
      }
      @include responsivemd {
        padding-left: 0;
        width: 100%;
      }
      ul {
        @include ul;
        padding: 20px;
        @include responsivemd {
          padding-top: 0;
        }

        li {
          @include card__margin;
        }
        .label {
          color: #bdbdbd;
          font-size: 16px;
          margin-bottom: 6px;
          font-weight: 400;
        }
        .card__title {
          color: #828282;
          font-size: 20px;
        }
      }
    }
    input {
      // height: 0;
      // width: 0;
      // overflow: hidden;
      opacity: 0;
    }
  }

  img {
    @include image;
    background-image: url("../images/dg_Placeholder.png");
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
  }
  .carousel--wrapper {
    margin-bottom: 50px;
    @include responsivemd {
      margin-bottom: 0;
    }
    .card__img {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      @include responsivemd {
        border-radius: 0;
      }
    }
  }
  .main--slider {
    &:hover {
      .slick-arrow {
        opacity: 1;
      }
    }
    @include responsive(1550px) {
      margin-bottom: 22px;
    }
    @include responsivesm {
      margin-bottom: 12px;
    }
    .card__img {
      height: 420px;
      // height: 65vh;
      // max-height: 650px;
      @include responsivetb {
        height: 340px;
      }
      @include responsivemd {
        height: 300px;
        min-height: 300px;
      }
      @include responsivesm {
        height: 210px;
        min-height: 210px;
      }
    }
  }
  .thumbnail-slider-wrap {
    .slick-track {
      display: flex;
      justify-content: center;
    }
    .slick-slide {
      width: 130px !important;
      margin: 0 8px;
      @include responsivemd {
        width: 80px !important;
        margin: 0 4px;
      }
    }
    .item {
      cursor: pointer;
    }
    .card__img {
      height: 80px;
      border: 3px solid transparent;
      border-radius: 10px;
      @include transition-in;
      @include responsivemd {
        height: 60px;
      }
      @include responsivesm {
        height: 54px;
        border-width: 2px;
      }
    }
    .slick-current {
      .card__img {
        border-color: $secondary-color;
      }
    }
  }
  .description--wrapper {
    padding: $modal--p;
    @include responsivemd {
      padding: 20px;
      padding-bottom: 0;
    }
    .para {
      color: #828282;
      font-size: 18px;
      line-height: 1.9;
      @include responsivemd {
        font-size: 16px;
      }
    }
  }

  .share {
    position: relative;
    // filter: url(#gooey);
    &:hover {
      .link {
        pointer-events: auto;
        z-index: 1;
        transform: none;
      }
    }
    .link {
      transition: 0.6s cubic-bezier(0.3, 0.44, 0.25, 1.41);
      pointer-events: none;
      z-index: -1;
      position: relative;
      @include responsivemd {
        display: none !important;
      }
    }

    button {
      margin-bottom: 0.5em;
      border: 0;
      @include responsivemd {
        background-color: #f2f2f2;
      }
      svg.active path {
        fill: #bdbdbd;
      }

      &.copy {
        background-color: $secondary-color;
        transform: translateY(-63px);
      }
      &.facebook {
        transform: translateY(-125px);
        background-color: #4267b2;
      }

      &.pintrest {
        transform: translateY(-190px);
        background-color: #e60023;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          height: 22px;
          width: 22px;
          background-size: 22px 22px;
        }
      }
      // .share-btn,
      // .share-btn > span,
      // .share-btn iframe {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   opacity: 0;
      //   height: 100% !important;
      //   width: 100% !important;
      //   z-index: 2;
      // }
    }
  }
  .shortcut--wrapper {
    @include responsivemd {
      display: none;
    }
  }

  .modal--bottom {
    @include responsive(1600px) {
      width: calc(100% - 60px);
    }
    @include responsivemd {
      width: 100%;
    }
    .grid {
      grid-template-columns: 1fr 1fr;
      @include responsivemd {
        grid-template-columns: 1fr;
        padding: 20px;
        background-color: #fff;
      }
    }
    .item:first-child {
      @include responsivemd {
        display: none;
      }
    }
    .card {
      border: none;
      padding: 0;
      @include border-radius;
      flex-direction: row;
      text-decoration: none;
      background-color: #fff;
      overflow: hidden;
      box-shadow: 0px 0px 20px rgba(3, 37, 108, 0.05);
      @include responsivemd {
        // border-radius: 0;
        background-color: $primary-color;
      }
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
      .card__body {
        padding: 30px;
        @include responsivemd {
          z-index: 2;
          width: 100%;
        }
      }
      .card__title {
        color: $dark;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 20px;
        @include responsivemd {
          color: #fff;
        }
      }

      .para {
        color: #bdbdbd;
        font-size: 17px;
        margin-bottom: 12px;
      }

      .card__img {
        height: 200px;
        width: 260px;
        overflow: hidden;
        @include responsivemd {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          // mix-blend-mode: multiply;
          opacity: 0.2;
        }
        img {
          @include transition-in;
        }
      }
    }
  }
  .toast {
    background-color: $primary-color !important;
    color: #fff;
    text-align: center;
    left: 50%;
    top: 20px;
    bottom: auto;
    transform: translateX(-50%);
    width: auto;
    @include border-radius;
    svg {
      margin-right: 1em;
    }
  }
}

.page-404 {
  .section__rule {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include responsivesm {
      padding-top: 0px;
      height: calc(100vh - 55px);
    }
  }
  h1.section__title {
    font-size: 34px;
    @include responsivemd {
      font-size: 28px;
    }
  }
  article {
    p {
      color: $para2;
      font-size: 20px;
      margin: 5vh 0;
      line-height: 1.6;
      @include responsivemd {
        font-size: 18px;
        margin-top: 1em;
      }
    }
    a {
      text-decoration: none;
      color: $secondary-color;
      margin: 0 6px;
    }
    svg {
      overflow: visible;
      @include responsivemd {
        height: auto;
        width: 100%;
      }
      .logoItem path:first-child {
        transform-origin: center 85%;
        @include transition;
      }
      &.active {
        .logoItem path:first-child {
          transform: rotateX(180deg);
        }
      }
    }
  }
  .wrapper--404 {
    margin-top: 50px;
  }
}

.page-404 + footer {
  color: $para2;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  @include responsivemd {
    text-align: center;
  }
  a {
    text-decoration: none;
  }
  .footer--bottom {
    @include responsivesm {
      font-size: 16px;
      align-items: center;
    }
  }
  & + footer {
    display: none;
  }
}

.contact--page {
  .project--template {
    margin-top: 100px;
    @include responsivemin(1700px) {
      margin-top: 250px;
    }
    @include responsivemd {
      margin-top: 0;
    }
  }
  .aside--left {
    width: 450px;
    max-width: 100%;
    padding-top: 70px;
    @include responsivemd {
      margin-bottom: 30px;
    }
  }
  .aside--right {
    h2.section__title {
      margin-bottom: clamp(60px, 10vh, 120px);
      font-size: 40px;
      @include responsivemd {
        font-size: 28px;
        margin-bottom: 1em;
      }
    }
    .PrivateTabIndicator-root-1 {
      display: none !important;
    }

    .card__wrapper {
      margin: 0 -15px;
      margin-bottom: calc(10vh + 50px);
      display: flex;
      flex-wrap: nowrap;
      @include responsivemd {
        @include scrollbar;
        scroll-behavior: smooth;
        @include transition;
        // margin: 0;
        margin-bottom: 60px;
      }
      @include responsivemmd {
        margin-left: -8px;
        margin-right: 0;
      }

      a {
        padding: 0 !important;
        width: 50%;
        max-width: none;
        margin: 0 15px;
        flex-shrink: 1;
        opacity: 1;
        min-width: 280px;
        white-space: nowrap;
        @include responsivemmd {
          margin: 0 8px;
          min-width: 260px;
          padding: 18px;
        }
        &.active {
          .card {
            border-color: $secondary-color;
            &:before,
            &:after {
              content: none;
            }
          }
        }
      }

      .card {
        text-align: left;
        @include border-radius;
        border: 2px solid #f2f2f2;
        padding: $common-margin--px + 20px $common-margin--px;
        text-transform: initial;
        box-shadow: none;
        width: 100%;
        position: relative;
        overflow: hidden;
        @include responsivemd {
          padding: 24px;
        }
        &.hello:before {
          content: "Say Hello";
        }
        .card__title {
          font-weight: bold;
          margin-bottom: 10px;
        }
        .para {
          color: #828282;
        }
        &:before {
          background-color: transparent;
          color: #fff;
          content: "Start";
          position: absolute;
          top: 100%;
          left: 0;
          height: 100%;
          width: 100%;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 48px;
          z-index: 8;
          @include transition-in;

          // clip-path: circle(0% at 50% 50%);
          @include responsivemd {
            font-size: 36px;
          }
        }
        &:after {
          background-color: $secondary-color;
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          height: 100%;
          width: 100%;
          @include transition-in;
        }
        &:hover:before {
          transition-delay: 0.1s !important;
        }
        &:hover:before,
        &:hover:after {
          @include transition-in;

          // clip-path: circle(75% at 50% 50%);
          top: 0;
        }
      }
    }
  }

  .contact__wrapper {
    margin-top: $common-margin--px;

    display: grid;
    grid-template: auto / repeat(2, 1fr);
    gap: 20px;
    max-width: 650px;
    @include responsivemd {
      grid-template: auto / repeat(1, 1fr);
    }
    .text-muted {
      color: #bdbdbd !important;
    }
    .para {
      color: $dark;
      margin-bottom: 5px;
      font-size: 18px;
    }
    article {
      margin-bottom: $common-margin--px;
      @include responsivemd {
        margin-bottom: 0;
      }
    }
  }
  .title--small {
    color: $para3;
  }
  @include boxForm;
  // .swipeable-container {

  .form-group {
    margin-bottom: 40px;
    .MuiInput-underline::after {
      border-color: $secondary-color;
    }
  }
  .title--wrapper {
    display: flex;

    .MuiSelect-select {
      background-color: transparent !important;
      &.MuiSelect-select {
        padding: 0 8px;
      }
    }
    .MuiInput-underline {
      background-color: transparent;
      svg {
        height: 12px;
        width: auto;
        stroke: $secondary-color;
      }
      &:after,
      &:before {
        content: none;
      }
    }
  }

  .text-input {
    .MuiFormControl-root {
      width: 100%;
    }
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
      font-size: 28px;
      @include responsivemd {
        font-size: 20px;
      }
    }
    .MuiInputBase-input.MuiInput-input {
      font-size: 24px;
      color: $dark;
      padding: 10px 0;
      @include responsivemd {
        font-size: 20px;
      }
    }

    .MuiInput-underline::before {
      border-color: #e0e0e0;
    }
  }

  button {
    @include button;
    // border-width: 1.6px;
    color: $dark;
    margin-bottom: 2em;
    @include responsivemd {
      width: 100%;
      padding: 0.7em;
    }
  }
  // }
  .file-upload--wrapper {
    position: relative;
    width: 210px;
    @include responsivesm {
      width: auto;
      margin-right: 1em;
    }
    &.active {
      width: auto;
      max-width: 100%;
      .fakelabel {
        border-color: $secondary-color;
      }
    }
  }
  .MuiInput-underline::before {
    border-width: 1.7px;
  }

  .file-upload {
    // position: absolute;
    // bottom: 0;
    // right: 0;
    // width: 70px;

    // @include responsivemd {
    //   position: relative;
    //   width: 60px;
    // }
    input {
      opacity: 0;
      cursor: pointer;
      width: 0;
      height: 0;
      position: absolute;
    }
    .fakelabel {
      // position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      bottom: 6px;
      left: 0;
      cursor: pointer;
      padding: 20px;
      font-size: 20px;
      border: 1.7px solid #e0e0e0;
      @include border-radius;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.6em;
      // position: relative;
      @include responsivemmd {
        // border: 2px solid rgba($para3, 0.5);
        padding: 16px;
        font-size: 18px;
        height: 48px;
        font-size: 15px;
      }
      .title--small {
        font-weight: 500;
      }
      svg {
        width: auto;
        height: 30px;
      }
      span {
        @include lineclamp(1);
      }
    }
    .delete--label {
      position: absolute;
      right: -14px;
      cursor: pointer;
      top: 21px;
    }
    .spinner-border {
      width: 50px;
      height: 50px;
      border: 3px solid #c1c1c1;
      border-right-color: transparent;
      position: absolute;
      bottom: 5px;
      right: 10px;
      transition: all 0.3s ease-in;
      @include responsivemd {
        right: 1px;
        bottom: 3px;
      }
      &.active {
        animation: none;
        border-radius: 0;
        height: 20px;
        transform: rotate(45deg);
        width: 10px;
        border-color: $secondary-color;
        border-top: 0;
        border-left: 0;
      }
    }
  }
  .budget {
    .list-group {
      @include responsivemd {
        grid-template: auto/repeat(2, 1fr);
      }
    }
  }
  .aside--left {
    @include responsivemd {
      display: block !important;
    }
  }
  .MuiInput-inputMultiline {
    line-height: 1.3;
    max-height: 120px;
  }
  .mb {
    display: flex;
    align-items: center;
    gap: 2em;
    @include responsivemd {
      flex-wrap: wrap;
      gap: 0;
    }
  }
}

.page-submit {
  text-align: left;
  .outline {
    @include button;
    color: $secondary-color;
    position: relative;
    z-index: 1;
  }
  .wrapper--404 {
    margin-bottom: 60px;
    @include responsivemd {
      margin-bottom: 30px;
    }
    .body {
      transform-origin: center;
      animation: rotate 3s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0.3s infinite
        forwards;
    }
    svg {
      @include responsivemd {
        height: 80px;
      }
    }
  }
  article {
    text-align: center;
    p {
      width: 100%;
      max-width: 850px;
    }
  }
  @include aside;
  .screen--template {
    .screen__inner {
      margin-top: 0px;
      max-width: 500px;
      width: 100%;
    }
    .row.no-gutter {
      @include responsivemd {
        flex-direction: column;
      }
    }
    @include responsivemd {
      padding-top: 60px;
    }
    aside:first-child {
      @include responsivemd {
        justify-content: center;
      }
    }
  }

  article {
    @include responsivemd {
      text-align: center;
    }
    text-align: left;
  }
  .row {
    width: 100%;
  }
  .wrapper--404 {
    svg {
      height: 300px;
      width: auto;
      @include responsivemd {
        height: 80px;
      }
    }
  }
  .screen--template {
    @include responsivemd {
      padding-top: 0;
    }
  }
  & + footer {
    @include responsivemd {
      position: relative;
      background-color: #fff;
    }
    .para {
      color: $dark !important;
    }
    .footer--bottom {
      @include responsivemd {
        align-items: center;
      }
    }
    .section__rule {
      @include responsivemd {
        padding-top: 0;
      }
    }
    address {
      @include responsivemd {
        margin-bottom: 2em;
      }
    }
  }
}

@import "./page";
