@import "./header";

.screen--template {
  max-height: 1080px;
  padding-top: 160px;
  @include responsivemd {
    height: auto;
  }
  img,
  video {
    width: 100%;
    height: auto;
    object-fit: contain;
    @include responsivemd {
      height: 500px;
      left: -90px;
      width: auto;
      top: -40px;
      position: relative;
    }
  }
  .screen__inner {
    margin-top: 0px;
  }
  .section__title {
    margin-bottom: 0;
  }
  .imgage--wrapper {
    flex-grow: 2;
    .image {
      @include responsivemd {
        height: 400px;
        width: 100%;
        overflow: hidden;
      }
    }
  }
  .col {
    position: relative;
  }
  aside:first-child {
    display: flex;
    align-items: center;
    padding: 0;
    min-width: max-content;
    @include responsivemd {
      position: relative;
      z-index: 1;
      justify-content: center;
    }
    @include responsivesm {
      justify-content: flex-start;
    }
  }
  .border-left {
    position: absolute;
    bottom: 0;
  }

  .row.no-gutter {
    @include responsivemd {
      flex-direction: column-reverse;
    }
  }
}

.screen--template--text {
  padding-top: 200px;
  @include responsivemd {
    padding-top: 70px;
  }
  .row.no-gutter {
    @include responsivemd {
      flex-direction: column;
    }
  }
  .screen__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    white-space: nowrap;
    .svg__absolute {
      position: absolute;
      top: 0;
      left: -58%;
      @include responsivetb {
        left: -20%;
      }
      @include responsivemd {
        height: 200px;
        left: 20px;
      }
    }

    svg {
      height: 300px;
      width: auto;
      overflow: visible;
      margin: 0 30px;
      @include responsivemd {
        height: 200px;
        margin: 0;
        margin-left: -80px;
      }
      @include responsivesm {
        display: none;
      }
    }
  }
  .image--wrapper {
    flex-grow: 2;
    .card__title {
      @include card-title;
      display: flex;
      -webkit-line-clamp: none;
      height: 100%;
      width: 490px;
      font-size: 20px;
      align-items: center;
      margin: auto;
      max-width: 100%;
      @include responsivetb {
        margin-top: 60px;
        margin-left: 0;
        font-size: 18px;
      }
      @include responsivesm {
        margin-top: 20px;
      }
    }
  }
}

.project--template {
  @include aside;
  @include card;
  .section__rule > .d-flex {
    @include responsivemd {
      flex-direction: column;
    }
  }
  .grid {
    grid-template-columns: 1fr 1fr;
    @include responsivemd {
      grid-template-columns: 1fr;
      margin-top: 30px;
    }
  }

  .aside--left {
    position: relative;
  }

  .section__title--lg {
    transform: rotate(-90deg) translate(-100%, 45px);
    top: auto;
    @include responsivemmd {
      transform: none;
    }
  }
  .aside--left__top {
    height: 100%;
    .trails-main {
      position: sticky;
      top: calc(10vh + 120px);
      bottom: 0;
    }
  }
  .section__title--sm {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .card {
    border: none;
    padding: 0;
    @include border-radius;

    .card__title {
      font-size: 28px;
      margin-bottom: 0;
      @include responsivemmd {
        font-size: 22px;
      }
    }
  }
}
