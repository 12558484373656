@import "./component_css/header";
@import "./component_css/commonCss";

body {
  background-color: #fff;
  margin: 0;
  padding: 0;
}

main {
  background-color: #fff;
  z-index: 1;
  scroll-behavior: smooth;
}
body.chrome .page--home {
  @include responsivemin(840px) {
    scroll-snap-type: y proximity;
  }
}

.page--home {
  height: 100vh;
  scroll-snap-type: y mandatory;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* Needed to work on iOS Safari */
  scrollbar-width: none;
  z-index: 1;
  position: relative;
  // y proximity
  &::-webkit-scrollbar {
    background: transparent; /* make scrollbar transparent */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
  @include responsivemd {
    scroll-snap-type: none;
    overflow-y: visible;
    height: 100%;
  }
  & > section {
    scroll-snap-align: start;
    z-index: 1;
    scroll-snap-stop: always;
  }

  .section__title {
    min-height: 180px;
    @include responsivemd {
      min-height: 100px;
    }
  }

  .screen {
    $screenTop: 160px;
    height: 100vh;
    max-height: 1080px;
    padding-top: $screenTop;
    @include responsivemd {
      padding-top: 0px;
      // height: 100%;
      height: calc(100vh - 50px);
    }
    overflow: hidden;
    .section__rule {
      position: relative;
      z-index: 8;
      @include responsivemd {
        padding-top: 30px;
      }
    }

    img,
    video {
      width: 100vw;
      max-width: 1920px;
      height: 100vh;
      max-height: 1080px;
      object-fit: contain;
      position: absolute;
      left: 40px;
      top: -$screenTop;
      z-index: 0;
      outline: none !important;
      @include responsivemin(1600px) {
        left: -50px;
      }
      &.active {
        opacity: 0;
      }
      @include responsivemd {
        width: 100vw;
        height: 100vw;
        object-fit: cover;
        position: absolute;
        left: calc(-5% - 8px);
        transform: scale(1.5);
        top: -10px;
      }
    }
    .row {
      flex-wrap: nowrap;
      @include responsivemd {
        flex-direction: column-reverse;
        @include responsivemd {
          min-height: calc(100vh - 300px);
        }
      }
    }
    .screen__inner {
      margin-top: 60px;
      @include responsivemin(1700px) {
        margin-top: 10vh;
      }
      @include responsivemd {
        margin-top: 0;
      }
    }
    .section__title {
      margin-bottom: 0.7em;
      @include responsivemd {
        margin-bottom: 1em;
      }
    }
    .imgage--wrapper {
      flex-grow: 2;
      .image {
        @include responsivemd {
          min-height: 380px;
          height: 53vh;
        }
      }
    }

    .aside__left {
      position: relative;
      z-index: 2;
      height: calc(100vh - #{$screenTop});
      max-height: calc(1080px - #{$screenTop} - 100px);
      @include responsivemin(1700px) {
        height: calc(90vh - #{$screenTop});
      }
    }

    .border-left {
      position: absolute;
      bottom: 120px;
      background: linear-gradient(180deg, #d0d0d0 45px, transparent 45px);
      background-position-y: 0;
      animation: bg_y 4s cubic-bezier(0.77, 0, 0.18, 1) 2s infinite forwards;
      width: 2px;
      @include responsivemd {
        display: none;
        opacity: 0;
      }
    }
  }

  .services {
    position: relative;
    &:before {
      content: "";
      @include position;
      background-color: $secondary-color;
      width: 33%;
      min-width: 400px;
      z-index: 0;
      @include responsive(1550px) {
        width: 30%;
      }
    }
    .section__rule > .d-flex {
      height: 100%;
    }
    * {
      z-index: 1;
    }
    a.para {
      text-decoration: none;
      color: #fff !important;
      font-weight: 600;
      font-size: 20px;
    }

    @include aside;
    @include card;

    .card {
      .card__img {
        @include card__margin;
        img {
          object-fit: contain;
          object-position: left bottom;
        }
      }
      .card__title {
        color: #fff;
        min-height: 60px;
      }
      .para {
        @include card__margin;
      }
    }

    @include carousel__item;
    @include slicknav;
    @include slickdots;

    .slick-slider {
      position: static;
    }
    // @include slickGrab;
  }

  .portfolio {
    position: relative;

    a.para {
      text-decoration: none;
      color: #fff !important;
      font-weight: 600;
      font-size: 20px;
    }

    .services--left {
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      justify-content: space-between;
      align-items: flex-start;
    }

    .services--right {
      width: calc(100% - 450px);
      position: relative;
    }

    @include card;

    .card {
      .card__img {
        @include card__margin;
        img {
          object-fit: contain;
          object-position: left bottom;
        }
      }
      .card__title {
        color: #fff;
        min-height: 60px;
      }
      .para {
        @include card__margin;
      }
    }
    @include carousel__item;
    @include slicknav;
    @include slickdots;
    .slick-slider {
      position: static;
    }
  }

  .brand {
    .section__rule {
      & > .d-flex {
        align-items: center;
        height: 100%;
        @include responsivemd {
          flex-direction: column;
          padding-top: 0px;
        }
      }
    }
    .brand--left {
      padding-right: $common-margin--px;
      white-space: nowrap;
      @include responsivemd {
        width: 100%;
        padding-right: 0;
      }
    }
    .brand--right {
      grid-template-columns: repeat(4, minmax(180px, 1fr));
      margin-top: 100px;

      @include responsivemd {
        grid-template-columns: repeat(2, 1fr);

        // grid-template-columns: repeat(6, minmax(150px, 1fr));
        // @include scrollbar;
        margin-top: 20px;
        width: 100%;
        @include responsivesm {
          margin-top: 20px;
        }
      }
      img {
        @include image;
        object-fit: contain;
        filter: grayscale(100);
        @include transition;
        transform: translateY(30px);
        opacity: 0.6;
      }
      &.active {
        .brand__card {
          &:after {
            transform: scaleY(0);
          }
        }
        img {
          transform: translateY(0px);
        }
      }
      img {
        transform: translateY(-200px);
        @include transition-in;
        transition-delay: 0.3s;
        @include responsivemd {
          transform: translateY(0px);
        }
      }
      .brand__card {
        height: 150px;
        border: 2px solid rgba(3, 37, 108, 0.05);
        box-sizing: border-box;
        @include border-radius;
        padding: 40px;
        text-align: center;
        overflow: hidden;
        position: relative;
        @include responsivemd {
          height: 100px;
          padding: 25px;
        }
        &:after {
          content: "";
          @include position;
          background-color: #fff;
          transform-origin: center top;
          transition: 1s 0.3s linear;
          @include responsivemd {
            content: none;
          }
        }

        &:hover {
          img {
            filter: grayscale(0);
            opacity: 1;
          }
        }
      }
    }
  }

  .clients {
    background-color: #f2f2f2;
    position: relative;
    @include slickdots;

    .section__rule {
      @include responsivesm {
        // height: calc(100vh - 56px);
        padding: 80px 0;
        padding-bottom: 130px;
      }

      & > .d-flex {
        justify-content: center;
        height: 100%;
        flex-direction: column;
        position: relative;
        @include clients--card;
      }
    }
    .slick-slider {
      position: static;
      @include responsivemd {
        // position: relative;
      }
    }

    .slick-dots {
      left: 0;
    }
    .card__top {
      svg {
        @include transition-in;
        // min-width: 50px;
      }
      &:hover {
        svg {
          transform: translateX(16px);
        }
      }
      .card__title {
        margin-bottom: 3px;
      }
      .card__bottom__right {
        margin-left: 3em;
        @include responsivemd {
          margin-left: 2em;
        }
      }
    }

    .client__top {
      svg {
        @include card__margin;
        @include transition-in;
      }
      .text-muted {
        color: #e0e0e0 !important;
      }
      .section__title--sm {
        font-weight: 600;
      }
    }

    .client__card {
      transform: translateX(-100px);
      opacity: 0;
      @include transition-in;
      p.card__title {
        font-weight: normal;
        font-size: 20px;
        // @include lineclamp(4);
        // @include lineclamp(8);
      }
    }
    .slick-active {
      .client__card {
        transform: translateX(0px);
        transition: 0.4s 0.7s ease-out;
        opacity: 1;
      }
    }
    .section__title--lg {
      @include responsivemd {
        display: none;
      }
    }
  }

  .roadmap {
    position: relative;

    .vh {
      scroll-snap-type: y mandatory;
      overflow-y: scroll;
      scrollbar-width: none;
      z-index: 1;
      position: relative;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        background: transparent; /* make scrollbar transparent */
        -webkit-appearance: none;
        width: 0;
        height: 0;
      }
      @include responsivemd {
        scroll-snap-type: block;
        overflow-y: visible;
      }
    }

    .d-flex {
      justify-content: center;
      // align-items: center;
      // height: 100%;
      position: relative;
      @include responsivemd {
        // height: 100%;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .aside__left {
      @include responsivemd {
        display: none;
      }
      svg {
        position: sticky;
        top: calc(50vh - 200px);
      }
      .dart {
        position: sticky;
        transition: 0.6s ease-in;
        z-index: 2;
        top: calc(100vh);
        transform: translate(-120%, 100px);
        visibility: hidden;
        width: auto;
        height: 254px;
        &.active {
          visibility: visible;
          top: calc(50vh - 15px);
          transform: translate(-56px, 0);
        }
      }
      .dg {
        stroke-dashoffset: 200px;
        stroke-linecap: butt;
        transition: stroke-dasharray 0.6s linear;
        stroke-dasharray: 410px, 1080px;
        transform: rotate(52deg);
        transform-origin: center;
      }

      rect {
        fill: $primary-color;
        transition: all 0.6s linear;
        transform: translate(4px, 250px) rotate(0deg);
        transform-origin: 50% -13%;
        &.last {
          transform: translate(266px, 250px);
          &.active {
            transform: translate(400px, 250px);
          }
        }
        &.active:not(.last) {
          // opacity: 0;
          height: 0;
          width: 0;
        }
      }
    }

    .aside__right {
      padding-top: 20vh;
      padding-bottom: 14vh;

      @include responsivemd {
        padding-top: 0;
        padding-bottom: 0;
      }
      @include responsivesm {
        margin: -20px 0;
      }
      .roadmap__wrapper {
        padding: 10vh 0;
        @include responsivemd {
          padding: 40px 0;
        }
        @include responsivesm {
          padding: 20px 0;
        }
      }
    }

    .roadmap__card,
    .aside__right__top {
      @include clients--card;
      width: calc(100% - 400px);
      margin-right: auto;
      margin-left: auto;
      @include responsivemd {
        margin-left: 0;
        width: 100%;
      }
    }

    .aside__right__top {
      display: flex;
      align-items: flex-end;
      @include responsivemd {
        align-items: center;
      }
      img {
        margin-right: 26px;
        width: auto;
        height: 70px;
        @include responsivemd {
          margin-right: 16px;
          height: 37px;
        }
        @include responsivemmd {
          height: 30px;
        }
      }
      .section__title {
        min-height: 40px;
        margin-bottom: 0;
        @include responsivemmd {
          font-size: 30px;
          // font-weight: 400;
        }
        // @include responsivesm {
        //   font-size: 24px;
        min-height: auto;
        // }
      }
    }
    .card__title {
      color: #fff;
      font-weight: 400;
      font-size: 20px;
      margin-top: 30px;
      opacity: 0.8;
      @include responsivemd {
        font-size: 17px;
        font-weight: 400;
        line-height: 1.7;
        color: #f2f2f2;
      }
    }

    &:before,
    &:after {
      content: "";
      background: linear-gradient(180deg, #03256c 0%, transparent 100%);
      background: -webkit-linear-gradient(
        top,
        #03256c 0%,
        rgba(3, 37, 108, 0) 100%
      );

      position: absolute;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 20vh;
      z-index: 2;
      min-height: 100px;
      max-height: 287px;
      @include responsivemd {
        content: none;
      }
    }
    &:after {
      background: linear-gradient(180deg, transparent 0%, #03256c 100%);
      background: -webkit-linear-gradient(
        bottom,
        #03256c 0%,
        rgba(3, 37, 108, 0) 100%
      );
      top: auto;
      bottom: 0px;
    }
    .section__title.section__title--lg {
      @include responsivesm {
        color: #fff;
        min-height: auto;
        margin: 0;
        padding-top: 60px;
        padding-left: 15px;
      }
    }
    // .roadmap__card {
    //   .card__title {
    //     @include responsivesm {
    //       display: none;
    //     }
    //   }
    // }
    .mobile--link {
      align-self: flex-end;
      // margin-bottom: 0.4em;
      margin-left: auto;
      margin-right: 2em;
    }
  }

  footer {
    scroll-snap-align: start;
    position: relative;
  }

  & + footer {
    display: none;
  }
}

@import "./component_css/footer";
