.page--blog {
  .pen {
    animation: writting 4s cubic-bezier(0.95, 0.05, 0.8, 0.04) 2s infinite
      forwards alternate;
    @include transition;
  }
  .screen {
    .section__rule {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include responsivesm {
        padding-top: 100px;
      }
    }
  }
  .image {
    svg {
      overflow: visible;
      @include responsivesm {
        // width: 90vw;
        height: 200px;
        width: auto;
      }
    }
    @include responsivesm {
      margin-top: 60px;
    }
  }
  .section__title--md {
    br {
      @include responsivesm {
        display: none;
      }
    }
    @include responsivemmd {
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1em;
    }
  }
  & + footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    .footer--bottom {
      @include responsivesm {
        text-align: center;
      }
    }
    & + footer {
      display: none;
    }
  }
  .row.no-gutter {
    @include responsivesm {
      flex-direction: column-reverse;
    }
  }
}
